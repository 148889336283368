import { Icon } from "@iconify/react";

const ContactInformation = () => {
  return (
    <div className="contact-information">
      <div>
        <h1>Contact Information</h1>
        <p>Say something to start a live chat!</p>
      </div>
      <ul className="links-button">
        <li>
          <Icon
            icon="mingcute:phone-call-line"
            width="1.2em"
            height="1.2em"
            style={{ color: "white" }}
          />
          <a href="tel:+1(650)600-6907"> +1 (650) 600-6907</a>
        </li>
        <li>
          <Icon
            icon="material-symbols:mail"
            width="1.2em"
            height="1.2em"
            style={{ color: "white" }}
          />
          <a href="mailto:info@twinly.ai">info@twinly.ai</a>
        </li>
        <li>
          <Icon
            icon="mdi:location"
            width="1.2em"
            height="1.2em"
            style={{ color: "white" }}
          />
          <span>
            <a
              href="https://www.google.com/maps/place/1007+Orange+St+4th+Floor+Ste+1382,+Wilmington,+DE+19801,+USA/@39.7466871,-75.5516656,17z/data=!3m1!4b1!4m5!3m4!1s0x89c6fd4074321447:0x2660fcdccc8154dc!8m2!3d39.746683!4d-75.5490907?entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
            >
              1007 N Orange St. 4th Floor Ste 1382, Wilmington, DE, US, 19801
            </a>
          </span>
        </li>
      </ul>
      {/* <ul className="wrapper">
        <li className="icon twitter">
          <span className="tooltip">twitter</span>
          <span>
            <a href="" target="_blank">
              <Icon icon="line-md:twitter-x-alt" width="1em" height="1em" />
            </a>
          </span>
        </li>
        <div className="position-relative">
          <li className="icon instagram">
            <span className="tooltip">instagram</span>
            <span>
              <a href=" https://t.me/meta_1st" target="_blank">
                <Icon icon="lets-icons:insta" />
              </a>
            </span>
          </li>
          <img src="/images/Vector.svg" alt="" />
        </div>
        <li className="icon telegram">
          <span className="tooltip">discord</span>
          <span>
            <a href=" https://t.me/meta_1st" target="_blank">
              <Icon icon="ic:baseline-discord" />
            </a>
          </span>
        </li>
      </ul> */}
    </div>
  );
};

export default ContactInformation;
