import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/mainstyle.scss";
import { MainRoutes } from "./routes";
function App() {
  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
