const VideoComponent = () => {
  return (
    <div className="container">
      <div className="lead-analysis">
        <h1 className="heading">How it works?</h1>
        <p className="mt-0">
          Discover how our AI seamlessly integrates with your channels, turning
          every interaction into a potential <br /> lead with round-the-clock,
          intelligent customer support tailored for law firms.
        </p>
      </div>
    </div>
  );
};

export default VideoComponent;
