import Footer from "../components/footer";
import Header from "../components/header";
import LeadAnalysisComponent from "../components/landing.components/ leadanalysis.component";
import BannerComponent from "../components/landing.components/banner.component";
import BlogsComponent from "../components/landing.components/blogs.component";
import ConversationsComponent from "../components/landing.components/conversations.component";
import GetTwinlyNowComponent from "../components/landing.components/gettwinlynow.component";
import PartnersComponent from "../components/landing.components/partners.component";
import StatisticsComponent from "../components/landing.components/statistics.component";
import TestimonialsComponent from "../components/landing.components/testimonials.component";
import VideoComponent from "../components/landing.components/video.component";

const HomePage = () => {
  return (
    <div>
      <Header />
      <BannerComponent />
      <StatisticsComponent />
      <LeadAnalysisComponent />
      <VideoComponent />
      <GetTwinlyNowComponent />
      <BlogsComponent />
      <PartnersComponent />
      <TestimonialsComponent />
      <ConversationsComponent />
      <Footer />
    </div>
  );
};

export default HomePage;
