import Footer from "../components/footer";
import Header from "../components/header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="terms-conditions">
        <h1 className="heading">Privacy Policy</h1>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
