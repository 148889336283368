import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import HeaderTwo from "../components/header/header-two";

const BookDemoPage = () => {
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload),
  });
  return (
    <div className="book-demo">
      <HeaderTwo image={<img src="/images/logo.svg" alt="logo" />} />

      <h1 className="heading">Book Demo</h1>
      <p>Any question or remarks? Just write us a message!</p>
      <div className="container mt-2">
        <InlineWidget url="https://calendly.com/twinly/30min" />
        <div className="d-flex justify-content-end">
          <img src="/images/letter_send 1.svg" alt="img not found" />
        </div>
      </div>
    </div>
  );
};

export default BookDemoPage;
