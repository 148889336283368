import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesConstants";

const GetTwinlyNowComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="Get-Twinly-Component">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <h1>We boost your growth as a successful Lawyer.</h1>
            <button
              className="button btn-4"
              onClick={() => navigate(routes.bookDemo)}
            >
              Get Twinly now
            </button>
          </div>
          <div className="col-lg-8 inner-content">
            <figure>
              <img src="/images/albert-img.svg" alt="images not found" />
            </figure>
            <figure>
              <div className="img-card">
                <div className="content">
                  <span>Albert Flord</span>
                  <p>Real Estate Lawyer</p>
                  <img
                    src="/images/gif-img-1.gif"
                    alt="gif"
                    className="hover-img"
                  />
                </div>
                <div>
                  <button onClick={() => navigate(routes.bookDemo)}>
                    Book Now
                  </button>
                  <img src="/images/circle-img.gif" alt="gif" />
                </div>
              </div>
            </figure>
            <figure>
              <div className="img-card">
                <div className="content">
                  <span>Mark Anderson</span>
                  <p>Corporate Lawyers</p>
                  <img
                    src="/images/gif-img-1.gif"
                    alt="gif"
                    className="hover-img"
                  />
                </div>
                <div>
                  <button onClick={() => navigate(routes.bookDemo)}>
                    Book Now
                  </button>
                  <img src="/images/circle-img.gif" alt="gif" />
                </div>
              </div>
            </figure>

            <figure>
              <img src="/images/img-2.svg" alt="images not found" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetTwinlyNowComponent;
