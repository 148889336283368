import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesConstants";

const BlogsComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="blogs" id="blog">
      <div className="blog-content">
        <div className="blog-header">
          <h1>Blog & News</h1>
          <p>
            Explore our latest blogs and news for innovative strategies and
            insights on transforming website visits into successful sales using
            modern technology possible only today. All of it, tailored
            specifically to law firms.
          </p>
          <div className="Learnmore__btn">
            <div title="Learn More" className="LearnMore__InnerText">
              Explore more{" "}
              <Icon
                icon="fluent:arrow-right-32-regular"
                width="1.2em"
                height="1.2em"
                style={{ color: "#2E2D2D" }}
              />
            </div>
          </div>
          <button
            className="button btn-4 "
            onClick={() => navigate(routes.bookDemo)}
          >
            Schedule a call
          </button>
        </div>
        <figure>
          <img src="/images/images-1.svg" alt="img not found" />
        </figure>
        <figure>
          <img src="/images/images-2.svg" alt="img not found" />
        </figure>
        <figure>
          <img src="/images/images-3.svg" alt="img not found" />
        </figure>
        <figure>
          <img src="/images/images-4.svg" alt="img not found" />
        </figure>
        <figure>
          <img src="/images/images-5.svg" alt="img not found" />
        </figure>
        <figure>
          <img src="/images/images-6.svg" alt="img not found" />
        </figure>
      </div>
      <div className="blog-content"></div>
    </div>
  );
};

export default BlogsComponent;
