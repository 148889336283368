import ContactInformation from "../components/contact-information";
import FormComponent from "../components/form-component";
import HeaderTwo from "../components/header/header-two";

const GetStartedPage = () => {
  return (
    <div className="book-demo">
      <HeaderTwo image={<img src="/images/logo.svg" alt="logo" />} />

      <h1 className="heading">Get Started</h1>
      <p>Submit the information and our agent will reach out to you. </p>
      <div className="container">
        <div className="form-content">
          <ContactInformation />
          <FormComponent />
        </div>
      </div>
    </div>
  );
};

export default GetStartedPage;
