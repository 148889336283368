import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesConstants";

const Button = ({ title }: any) => {
  const navigate = useNavigate();
  return (
    <button
      className="custom-btn btn-8"
      onClick={() => navigate(routes.bookDemo)}
    >
      {title}
    </button>
  );
};

export default Button;
