import { BrowserRouter, Route, Routes } from "react-router-dom";
import BookDemoPage from "../view/book-demo.page";
import GetStartedPage from "../view/get-started.page";
import HomePage from "../view/home.page";
import PricingPage from "../view/pricing.page";
import PrivacyPolicy from "../view/privacy-policy.page";
import TermsConditions from "../view/terms-conditions.page";
import { routes } from "./routesConstants";

export const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.homePage} element={<HomePage />} />
        <Route path={routes.termsConditions} element={<TermsConditions />} />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={routes.bookDemo} element={<BookDemoPage />} />
        <Route path={routes.pricing} element={<PricingPage />} />
        <Route path={routes.getStarted} element={<GetStartedPage />} />
      </Routes>
    </BrowserRouter>
  );
};
