import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { routes } from "../../routes/routesConstants";
import Button from "../header/button";
const BannerComponent = () => {
  const navigate = useNavigate();
  // const ref = useRef(null);
  var settings = {
    dots: true,
    arrows: false,
    verticalSwiping: true,
    autoplay: true,
    speed: 500,
    adaptiveHeight: true,
    vertical: true,
    infinite: true,
  };
  const _sliders = [
    {
      main: "Pay only for the Leads that Matter",
      sub: "Our success comes from more people getting the right representation. Performance payment plans mean that you pay when the chatbot works for you",
    },
    {
      main: "Effortlessly Prioritize Vital Conversations",
      sub: "Automatically screen conversations not essential to your sales process. Gain back hours to spend more time on what matters most.",
    },
    {
      main: "Maximize Conversion of Website Traffic into Clients",
      sub: "Our sales-trained bots drive campaign performance. Use them to instantly boost your conversion rates.",
    },

    {
      main: "Maintain a Consistent Brand Voice",
      sub: "Attract quality clients with a steady messaging voice. Worry less about consistency in sales chats.",
    },
    {
      main: "Qualify Promising Leads around the clock in Multiple Languages",
      sub: "Use the 24/7 availability in multiple languages to position your firm that’s always there for any client.",
    },
    {
      main: "Efficiently Filter ONLY Pre-qualified Leads",
      sub: "Save time filtering through lead conversations. Our enhanced AI system picks up on traces of buying signals within conversations so you don’t have to",
    },
  ];
  return (
    <div className="container">
      <div className="banner-component" id="home">
        <div className="row">
          <div className="col-lg-6">
            <h1>Your Law Firm's Frontline in Converting Better Leads</h1>
            <h3>Unlock Seamless Client Engagement</h3>
            <p>
              Pay for meaningful leads, prioritize vital conversations, and
              maximize conversion rates with our sales-trained bots, all while
              being available 24/7 in multiple languages. Only for law
              practices!
            </p>
            <div className="c">
              <img src="/images/494_finance.svg" alt="img not found" />
              <div>
                <Slider className="video-slider" {...settings}>
                  {_sliders.map((item, index) => (
                    <div className="single-slide" key={`video-slide-${index}`}>
                      <div className="line">
                        <h2 className="lineUp">{item.main}</h2>
                        <p className="lineUp hover-underline-animation ">
                          {item.sub}
                        </p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <button
              className="button btn-4"
              onClick={() => navigate(routes.bookDemo)}
            >
              {" "}
              Try scheduling
            </button>
          </div>
          <div className="col-lg-6">
            <div className="features-content">
              <div className="d-flex justify-content-end">
                <Button title="Book Demo" />
              </div>
              <div className="d-flex">
                <img
                  src="/images/vectary-texture-img.svg"
                  alt="img not found"
                  className="img"
                />
                <div className="feature-inner-content">
                  <p>Explore our latest features</p>
                  <button className="feature-button" style={{ cursor: "auto" }}>
                    🚀 Elevate Your Practice
                  </button>
                  <button className="feature-button" style={{ cursor: "auto" }}>
                    🌐 Integration Made Easy
                  </button>
                  <button className="feature-button" style={{ cursor: "auto" }}>
                    🔒 Secure and Compliant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
