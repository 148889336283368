import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "./button";
import "./header.scss";
const Header = () => {
  const location = useLocation();

  useEffect(() => {
    const targetElement = document.getElementById(location.hash.substring(1));

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  const navMenu = [
    { menuName: "Home", to: "/" },
    { menuName: "Features", to: "#features" },
    { menuName: "Blog", to: "#blog" },
    { menuName: "Partners", to: "#partners" },
  ];
  const [openMenu, setOpenMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1000);
    });
  }, []);
  return (
    <div className="container">
      <div className="land-header">
        <figure className="mb-0">
          <a href="/">
            <img src="/images/logo.svg" alt="logo" />
          </a>
        </figure>
        <ul className="ul">
          {navMenu.map((item, i) => (
            <li key={`menu-item-${i}`}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-item menu-item" : "menu-item"
                }
                to={item.to}
              >
                {item.menuName}
              </NavLink>
            </li>
          ))}
        </ul>
        {/* <ul>
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#">Features</a>
          </li>
          <li>
            <a href="#">Blog</a>
          </li>
          <li>
            <a href="#">Partners</a>
          </li>
        </ul> */}
        <Button title="Book Demo" />
      </div>
      <div className="header">
        <div className="d-flex justify-content-between">
          <figure className="mb-0">
            <a href="/">
              <img src="/images/logo.svg" alt="logo" />
            </a>
          </figure>
          <div className="toggle-btn">
            <button
              className="btn btn-toggle ml-2 m-0"
              onClick={() => setOpenMenu(true)}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        {/* html for mobile */}
        <nav className={openMenu ? "mobile-menu" : "nav-menu"}>
          {openMenu && <div className="overlay"></div>}
          <div className="navbar-menu">
            {openMenu && (
              <button className="close-btn" onClick={() => setOpenMenu(false)}>
                <Icon icon="akar-icons:cross" />
              </button>
            )}

            <ul className="ul">
              {navMenu.map((item, i) => (
                <li key={`menu-item-${i}`}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active-item menu-item" : "menu-item"
                    }
                    to={item.to}
                  >
                    {item.menuName}
                  </NavLink>
                </li>
              ))}
            </ul>
            <Button title="Book Demo" />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
