import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./footerstyle.scss";
const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    const targetElement = document.getElementById(location.hash.substring(1));

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  const links = [
    {
      heading: "Top Links",
      innerLink: [
        {
          linkText: "Home",
          path: "#home",
        },
        {
          linkText: "Features",
          path: "#features",
        },
        {
          linkText: "Blog ",
          path: "#blog",
        },
        {
          linkText: "Partners ",
          path: "#partners",
        },
      ],
    },
    {
      heading: "Support",
      innerLink: [
        {
          linkText: "Terms & Conditions",
          path: "/",
        },
        {
          linkText: "Privacy Policy",
          path: "/",
        },
      ],
    },
  ];
  return (
    <div className="container">
      <div className="footer">
        <div className="row">
          <div className="col-lg-6">
            <figure className="mb-0">
              <img src="/images/logo.svg" alt="logo" />
            </figure>
            <p>Every conversation can be a client</p>
            <div className="input-field">
              <label>Subscribe to newsletter </label>
              <div className="position-relative">
                <input type="text" placeholder="Your Email Address" />
                <button className="subscribe-btn" disabled>
                  Subscribe
                </button>
              </div>
            </div>
          </div>
          {links.map((item, i) => (
            <div className="col-3 mt-lg-0 mt-4" key={i}>
              <h4>{item.heading}</h4>
              <ul>
                {item?.innerLink?.map((items, index) => (
                  <li key={index}>
                    <NavLink to={items.path}>{items.linkText}</NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <p className="mt-5 bottom-text">
          © 2024 Twinly AI, All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
