import { useMemo, useState } from "react";
import countryList from "react-select-country-list";
const FormComponent = () => {
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value: any) => {
    setValue(value);
  };
  return (
    <div className="form-component">
      {/* <form>
        <div className="row">
          <div className="col-lg-6">
            <div className="input-field">
              <label htmlFor="">Name</label>
              <input type="text" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-field">
              <label htmlFor="">Company Name</label>
              <input type="text" placeholder="Doe" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-field">
              <label htmlFor="">Country</label>
              <Select
                options={options}
                value={value}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-field">
              <label htmlFor="">Industry</label>
              <input type="text" placeholder="Doe" />
            </div>
          </div>
        </div>
      </form> */}
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSd6Y-Bx895nc-8Fq08gPSrcAWyBZbnyiXWVNrSTF5o79lFIgw/viewform?embedded=true"
        width="100%"
        height="480px"
        // frameBorder="0"
        // marginHeight="0"
        // marginWidth="0"
      >
        Loading…
      </iframe>
      {/* <div className="d-flex justify-content-end">
        <button className="custom-btn btn-8">Submit</button>
      </div> */}
      {/* <div className="d-flex justify-content-end">
        <img src="/images/letter_send 1.svg" alt="img not found" />
      </div> */}
    </div>
  );
};

export default FormComponent;
