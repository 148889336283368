import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "./button";
import "./header.scss";
const HeaderTwo = ({ image }: any) => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const targetElement = document.getElementById(location.hash.substring(1));

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  const navMenu = [
    { menuName: "Home", to: "/" },
    { menuName: "Features", to: "#features" },
    { menuName: "Blog", to: "#blog" },
    { menuName: "Partners", to: "#partners" },
  ];
  return (
    <>
      <div className="land-header header-2">
        <figure className="mb-0">
          <a href="/">{image}</a>
        </figure>
        <div className="d-flex align-items-center bookdemo-btn">
          <ul className="ul">
            {navMenu.map((item, i) => (
              <li key={`menu-item-${i}`}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-item menu-item" : "menu-item"
                  }
                  to={item.to}
                >
                  {item.menuName}
                </NavLink>
              </li>
            ))}
          </ul>

          <Button title="Book Demo" />
        </div>
      </div>
      <div className="header header-2">
        <div className="d-flex justify-content-between">
          <figure className="mb-0">
            <a href="/">
              <img src="/images/logo.svg" alt="logo" />
            </a>
          </figure>
          <div className="toggle-btn">
            <button
              className="btn btn-toggle ml-2 m-0"
              onClick={() => setOpenMenu(true)}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        {/* html for mobile */}
        <nav className={openMenu ? "mobile-menu" : "nav-menu"}>
          {openMenu && <div className="overlay"></div>}
          <div className="navbar-menu">
            {openMenu && (
              <button className="close-btn" onClick={() => setOpenMenu(false)}>
                <Icon icon="akar-icons:cross" />
              </button>
            )}

            <div className="d-flex align-items-center bookdemo-btn">
              <ul className="ul">
                {navMenu.map((item, i) => (
                  <li key={`menu-item-${i}`}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active-item menu-item" : "menu-item"
                      }
                      to={item.to}
                    >
                      {item.menuName}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <Button title="Book Demo" />
          </div>
        </nav>
      </div>
    </>
  );
};

export default HeaderTwo;
