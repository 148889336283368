import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesConstants";

const ConversationsComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="conversations-component">
      <div className="container">
        <div className="conversations-content">
          <div className="row  align-items-center">
            <div className="col-lg-6">
              <h1>Supercharge your traffic conversions with Twinly.</h1>
              <p>Tailor-made for Law Firms</p>
            </div>
            <div className="col-lg-6">
              <div className="d-flex gap-4 justify-content-lg-end  mt-lg-0 mt-3">
                <button
                  className="custom-btn btn-8 contact-btn "
                  onClick={() => navigate(routes.getStarted)}
                >
                  Contact Us
                </button>
                <button
                  className="custom-btn btn-8 "
                  onClick={() => navigate(routes.bookDemo)}
                >
                  Schedule a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationsComponent;
