import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import HeaderTwo from "../components/header/header-two";
import { routes } from "../routes/routesConstants";

const PricingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderTwo image={<img src="/images/black-logo.svg" alt="logo" />} />
      <div className="pricing">
        <div className="row">
          <div className="col-lg-3 position-relative">
            <div className="pricing-inner-content">
              <h1>Unlock the potential within you</h1>
              <img src="/images/sitting-office.svg" alt="image not found" />
            </div>
          </div>
          <div className="col-lg-9">
            <div className="pricing-content">
              <h1>The Perfect Plan for Your Needs</h1>
              <p>
                Embark on a transformative legal journey with Twinly.ai – sign
                up now for our 30-day free trial. Experience the power of an
                always-on virtual assistant, streamlined lead management, and
                cost-efficient AI innovation. Unleash the potential of your
                legal practice risk-free – your future awaits with Twinly.ai
              </p>
              <div className="pricing-card">
                <div className="card-left">
                  <h2>Premium PRO</h2>
                  <h1>$8</h1>
                  <p>Per SQL (sales-qualified lead)</p>
                  <button
                    className="custom-btn btn-8 w-100"
                    onClick={() => navigate(routes.getStarted)}
                  >
                    Get Started
                  </button>
                </div>
                <div className="card-right">
                  <p>
                    Access these features when you get this pricing package for
                    your business.
                  </p>
                  <ul>
                    <li>
                      <Icon
                        icon="mdi:tick-circle"
                        width="1.2em"
                        height="1.2em"
                        style={{ color: "#0F5265" }}
                      />
                      Unlimited parallel chat conversations.
                    </li>
                    <li>
                      <Icon
                        icon="mdi:tick-circle"
                        width="1.2em"
                        height="1.2em"
                        style={{ color: " #0F5265" }}
                      />
                      24/7 customer support for all your needs.
                    </li>
                    <li>
                      <Icon
                        icon="mdi:tick-circle"
                        width="1.2em"
                        height="1.2em"
                        style={{ color: "#0F5265" }}
                      />
                      Conversation intelligence.
                    </li>
                    <li>
                      <Icon
                        icon="mdi:tick-circle"
                        width="1.2em"
                        height="1.2em"
                        style={{ color: "#0F5265" }}
                      />
                      Customizable qualifying questions
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPage;
