import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesConstants";

const LeadAnalysisComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="lead-analysis">
        <h1 className="heading">Why you need Twinly?</h1>

        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col-lg-4">
            <div className="cards">
              <div>
                <h1>Unmatched Affordability</h1>
                <p>
                  Experience the power of advanced technology without breaking
                  the bank. Twinly offers a remarkable four-fold reduction in
                  costs compared to competitors, ensuring cutting-edge legal
                  support without the hefty price tag
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <div className="sql-card">
                  <h1>
                    <sup>$</sup>8
                  </h1>
                  <h6>Per SQL</h6>
                  <span>Setup Fee</span>
                  <p>
                    $200 charged upon contract signing for technical
                    integration.
                  </p>
                  <button
                    className="custom-btn btn-8"
                    onClick={() => navigate(routes.pricing)}
                  >
                    Get Started Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cards active-card">
              <h1>Intelligent Multitasking with Twinly's chatbot</h1>
              <p>
                While you're focused on your legal expertise, Twinly's chatbot
                is tirelessly working to convert leads. No more missed
                opportunities - your virtual assistant engages potential clients
                seamlessly.
              </p>
              <button
                className="button btn-4"
                onClick={() => navigate(routes.bookDemo)}
              >
                Try scheduling
              </button>
              <div className="d-flex justify-content-end mt-1 gif-img">
                <img src="/images/Intelligent.gif" alt="img not found" />
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="cards lead-card">
              <div>
                <h1>Streamlined Lead Management</h1>
                <p>
                  Bid farewell to chaos. Twinly keeps your leads organized,
                  allowing you to prioritize and address inquiries efficiently.
                  A structured approach to lead management that enhances
                  productivity.
                </p>
              </div>

              <div className="d-flex justify-content-end  mt-1">
                <img
                  src="/images/vectary-texture.svg"
                  alt="img not found"
                  className="img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cards pb-4 pt-4">
          <div className="row align-items-center">
            <div className="col-lg-6 ">
              <h1>Insightful Lead Analysis</h1>
              <p>
                Gain a competitive edge with Twinly's lead analysis tools.
                Understand client behavior, preferences, and trends to make
                informed decisions. Elevate your legal practice by harnessing
                the true potential of data-driven insights.
              </p>
              <button
                className="button btn-4 book-demo-btn"
                onClick={() => navigate(routes.bookDemo)}
              >
                Book Demo
              </button>
            </div>
            <div className="col-lg-6 d-flex justify-content-lg-end total-lead-img">
              <img src="/images/Insights-img.svg" alt="image not found" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadAnalysisComponent;
