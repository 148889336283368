import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesConstants";

const partner = [
  {
    imgPath: "/images/logo-1.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-2.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-3.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-4.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-5.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-6.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-7.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-8.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-9.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-10.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-11.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-12.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-13.svg",
    link: "",
  },
  {
    imgPath: "/images/logo-14.svg",
    link: "",
  },
];
const PartnersComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="partners-component">
      <div className="container">
        <span>Plug in and Go. No Fuss.</span>
        <h1 className="heading">We Work with Your Software</h1>
        <p>
          Seamlessly synchronize Twinly with your existing software. Track
          calls, chats, and appointments effortlessly across 100+ platforms
          you're <br /> already familiar with. Our hassle-free integration
          process lets you focus on what you do best: managing your law
          practice.
        </p>
        <div className="partner-content">
          <div className="partner-name">
            {partner.map((item, index) => (
              <a href={item.link} key={index}>
                <img src={item.imgPath} alt="logo" />
              </a>
            ))}
          </div>
          <p>Don't see your software listed here?</p>
          <button
            className="button btn-4"
            onClick={() => navigate(routes.bookDemo)}
          >
            Request an Integration
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartnersComponent;
