import { Icon } from "@iconify/react";
import { useState } from "react";
import { useNavigate } from "react-router";

const TestimonialsComponent = () => {
  const navigate = useNavigate();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index: any) => {
    setSelectedImageIndex(index);
  };

  const handleSpeakButtonClick = () => {
    navigate("/book-demo");
  };

  const TestimonialsData = [
    {
      description:
        " Twinly has been a game-changer for us! We used to drown in a sea of leads, struggling to respond quickly to the most time-sensitive   ones. With Twinly's effortless conversation prioritization, we now   focus on the vital chats, saving us precious hours every day. It's   like having a dedicated assistant to ensure we never miss an   opportunity.",
      clientName: "Zain Qureshi",
      designation: "Partner, MAQ & Co.",
      img: "/images/Zain-Qureshi.jpeg",
    },
    {
      description:
        "“The 24/7 multilingual support by Twinly sets us apart in the industry. Clients appreciate that we're always there for them, no matter where they are or what language they speak. It's a unique selling point that has started bringing us valuable clients we wouldn't have reached otherwise.",
      clientName: "Ahsan Ahmed Munir",
      designation: "Partner, Munir & Munir",
      img: "/images/Ahsan-Ahmed-Munir.jpeg",
    },
    {
      description:
        "Twinly's sales-trained bots are like having a dedicated sales team on our website. We've seen a remarkable boost in our conversion rates since implementing Twinly. It's incredible how it instantly engages visitors and guides them towards becoming clients.",
      clientName: "Barrister Umer Abdullah",
      designation: "Partner, Abdullah&Hussain",
      img: "/images/Umer-Abdullah.jpeg",
    },
    {
      description:
        "Twinly's advanced analytics have been a game-changer for our marketing strategies. We're now able to pinpoint exactly where our most promising leads come from and tailor our approach accordingly. The performance-based pricing model means we're investing our resources more wisely than ever. It's efficient, effective, and pays for itself.",
      clientName: "Kamran Bashir Mughal",
      designation: "Vice Chairman, Punjab Bar Council",
      img: "/images/Kamran-Bashir-Mughal.png",
    },
  ];

  return (
    <div className="container" id="partners">
      <div className="lead-analysis">
        <h1 className="heading mb-0">Hear from Our Partners</h1>
        <p>
          Discover how leading law firms have revolutionized their client <br />
          engagement and lead generation with Twinly. Read their success stories
          here.
        </p>

        <div className="ClientStories">
          {TestimonialsData.map((item, i) => (
            <div
              className={`ClientStories__content ${
                i === selectedImageIndex ? "selected" : "hidden"
              }`}
              key={i}
            >
              <p>{item.description}</p>
              <div className="slider-description">
                <div className="d-flex my-4">
                  <div className="flex-shrink-0">
                    <img src={item.img} alt="..." />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5>{item.clientName}</h5>
                    <span>{item.designation}</span>
                  </div>
                </div>
                <button
                  className="custom-btn btn-8 speak-btn"
                  onClick={handleSpeakButtonClick}
                >
                  Speak to us
                </button>
              </div>
            </div>
          ))}
          <div className="ClientStories__slider">
            {TestimonialsData.map((item, i) => (
              <figure
                key={i}
                className={i === selectedImageIndex ? "active" : ""}
              >
                <img
                  src={item.img}
                  alt="client image"
                  className={`image-${i + 1}`}
                  onClick={() => handleImageClick(i)}
                />
                {i !== selectedImageIndex && (
                  <Icon
                    icon="quill:arrow-right"
                    width="1.2em"
                    height="1.2em"
                    style={{ color: "#FFE143" }}
                  />
                )}
              </figure>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsComponent;
