import { Icon } from "@iconify/react";

const StatisticsComponent = () => {
  return (
    <div className="container" id="features">
      <div className="statistics">
        <h1 className="heading">What is Twinly AI</h1>

        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-5">
                <div className="cards">
                  <h1>Your always-on virtual assistant</h1>
                  <p>
                    Twinly serves as your 24/7 virtual assistant, actively
                    converting leads and ensuring no opportunity is missed.
                  </p>
                  <div className="d-flex justify-content-end">
                    <img src="/images/card-img.svg" alt="img not found" />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="cards">
                  <h1>Organized lead management hub</h1>
                  <p>
                    Twinly provides an intuitive dashboard for streamlined lead
                    management, keeping your leads organized and insights at
                    your fingertips.
                  </p>
                  <div className="d-flex justify-content-center mt-4">
                    <img src="/images/card-img1.svg" alt="img not found" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cards">
                  <h1>Statistics</h1>
                  <p>Keep track of your business statistics.</p>
                  <div className="d-flex justify-content-end">
                    {/* <img src="/images/card-img.svg" alt="img not found" /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cards">
                  <h1>AI in your control</h1>
                  <p>
                    Disable AI anytime to personally engage, providing a
                    seamless blend of automation and customization.
                  </p>
                  <div className="d-flex  mt-1 gap-2">
                    <button className="button" style={{ cursor: "auto" }}>
                      Disable AI
                    </button>
                    <button className="button" style={{ cursor: "auto" }}>
                      <Icon
                        icon="ph:eye-bold"
                        width="1.2em"
                        height="1.2em"
                        className="me-1"
                        style={{ color: "white" }}
                      />
                      Preview Chatbot
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="cards">
              <h1>Flexible & personalized engagement</h1>
              <p>
                Take control with Twinly – disable AI anytime to engage
                personally, striking the perfect balance between automation and
                customization.
              </p>

              <div className="inner-card">
                <img src="/images/card-profile.png" alt="img not found" />
                <h1>Cameron Williamson</h1>
                <p>Criminal Lawyer</p>
                <ul>
                  <li>
                    <Icon
                      icon="simple-line-icons:calender"
                      width="1.2em"
                      height="1.2em"
                      style={{ color: "#fff" }}
                    />
                  </li>
                  <li>
                    <Icon
                      icon="material-symbols-light:mail-outline"
                      width="1.2em"
                      height="1.2em"
                      style={{ color: "#fff" }}
                    />
                  </li>
                  <li>
                    <Icon
                      icon="fluent:video-32-regular"
                      width="1.2em"
                      height="1.2em"
                      style={{ color: "#fff" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsComponent;
